import { Frame } from "src/app/core/models/domain/Frame";
import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { MapEventObserver } from "src/app/core/svc/resource-svc/map/map.event.observer";
import { MapEventTypes } from "../models/MapEventTypes";
import { FrameLegendBy } from "plato-map-lib";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { toggleLayer } from "./map-box-layers-utils";
import { AudienceIndexLayerManager } from "./audience-index-layer-manager";
import { AudienceIndexHeatmapLayerManager } from "./audience-index-heatmap-layer-manager";
import _ from "underscore";
import { applyFilter } from "./mapbox-utils";

const environment = new ConfigurationService().getConfig();
export const FRAME_SOURCE_ID = "frameSource";
export const FRAME_ONLY_SOURCE_ID = "frameOnlySource";

@Injectable({
  providedIn: "root",
})
export class FrameLayersManager {
  regionBasedFrameColorCoding = environment.frameColorCoding;
  private mapEventObserver: MapEventObserver;
  private readonly FRAME_UNCLUSTERED_POINT_LAYER_ID = "frameUnclusteredPoint";
  private readonly FRAME_ICON_OUTER_LAYER_ID = "frameOuterLayerIcons";
  private readonly FRAME_ICON_LAYER_ID = "frameIcons";
  private readonly FRAME_LABEL_LAYER_ID = "frameLabels";

  private readonly FRAME_UNCLUSTERED_LAYER_MIN_ZOOM = 0;
  private readonly FRAME_UNCLUSTERED_LAYER_MAX_ZOOM = 14;
  private readonly FRAME_ICON_LAYER_MIN_ZOOM = 14;
  private readonly DEFAULT_ORIENTATION_DEGREE = 0;

  private popUp: mapboxgl.Popup;
  private map: mapboxgl.Map;
  private functionToUpdateFrameDetailsPopUp;
  private layerPaintColorExpression: any[];
  private latMin: number;
  longMin: number;
  latMax: number;
  longMax: number;
  frameData: Frame[] = [];

  private frameLayers: string[] = [];
  showLocationLayer: boolean = false;

  constructor(
    mapEventObserver: MapEventObserver,
    private audienceIndexLayer: AudienceIndexLayerManager,
    private audienceIndexHeatmapLayer: AudienceIndexHeatmapLayerManager,
    private configurationService: ConfigurationService
  ) {
    this.mapEventObserver = mapEventObserver;
    this.frameLayers.push(
      this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
      this.FRAME_ICON_LAYER_ID,
      this.FRAME_ICON_OUTER_LAYER_ID,
      this.FRAME_LABEL_LAYER_ID,
      ...audienceIndexLayer.getLayerIds(),
      ...audienceIndexHeatmapLayer.getLayerIds()
    );
  }

  public getLayerIds(): string[] {
    return this.frameLayers;
  }

  private frameLayerHighlightPaintColorExpression(value, defaultValue, selectedFrameIds) {
    return ["match", ["get", "frameId"], ["literal", ...selectedFrameIds], value, defaultValue];
  }

  public setUpFramelayersData(map: mapboxgl.Map, popUp: mapboxgl.Popup, functionToUpdateFrameDetailsPopUp) {
    this.map = map;
    this.showLocationLayer = this.configurationService.getConfig()?.mapConfig?.showLocationLayer;
    this.functionToUpdateFrameDetailsPopUp = functionToUpdateFrameDetailsPopUp;
    this.popUp = popUp;
    this.displayFrameDetailsForLayers([this.FRAME_UNCLUSTERED_POINT_LAYER_ID, this.FRAME_ICON_LAYER_ID]);
    this.setupFrameSource();
    this.loadFrameImagesforMap();
  }

  public updateFrameLayers(frameData: Frame[]) {
    const features = [];
    this.latMin = 90;
    this.longMin = 180;
    this.latMax = -90;
    this.longMax = -180;
    frameData.forEach((frame) => {
      features.push(this.convertToGeoJson(frame));
      if (frame.geography.location.coordinates[1] !== 0 && frame.geography.location.coordinates[1] !== 0) {
        this.latMin = Math.min(this.latMin, frame.geography.location.coordinates[1]);
        this.longMin = Math.min(this.longMin, frame.geography.location.coordinates[0]);
        this.latMax = Math.max(this.latMax, frame.geography.location.coordinates[1]);
        this.longMax = Math.max(this.longMax, frame.geography.location.coordinates[0]);
      }
    });
    this.frameData = frameData;
    this.updateFrameSource(features);
  }

  public getMapBoundsForAllFrames() {
    return [
      [this.longMin, this.latMin],
      [this.longMax, this.latMax],
    ];
  }

  public applyLegendColorToFrameLayers(colorCodeMapping: any, selectedFrameIds: string[], frameLegendBy: string) {
    const colorExpression = [];
    if (!this.map) {
      return;
    }

    if (Object.keys(colorCodeMapping).length !== 0) {
      Object.entries(colorCodeMapping).forEach(([key, value]) => {
        colorExpression.push(key);
        colorExpression.push(value);
      });
      this.layerPaintColorExpression = [
        "match",
        ["get", frameLegendBy == FrameLegendBy.MediaOwner ? "mediaOwnerName" : "formatName"],
        ...colorExpression,
        "#11b4da",
      ];

      if (this.regionBasedFrameColorCoding) {
        this.map.setPaintProperty(
          this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
          "icon-color",
          this.frameLayerHighlightPaintColorExpression(
            "#000",
            this.layerPaintColorExpression || "#11b4da",
            selectedFrameIds
          )
        );
        this.map.setPaintProperty(
          this.FRAME_ICON_LAYER_ID,
          "icon-color",
          this.frameLayerHighlightPaintColorExpression(
            "#000",
            this.layerPaintColorExpression || "#11b4da",
            selectedFrameIds
          )
        );
      } else {
        this.map.setPaintProperty(
          this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
          "circle-color",
          this.layerPaintColorExpression
        );
        this.map.setPaintProperty(
          this.FRAME_ICON_LAYER_ID,
          "icon-color",
          this.frameLayerHighlightPaintColorExpression(
            "#1777A7",
            this.layerPaintColorExpression || "#11b4da",
            selectedFrameIds
          )
        );
      }
      this.map.setPaintProperty(
        this.FRAME_ICON_OUTER_LAYER_ID,
        "icon-color",
        this.frameLayerHighlightPaintColorExpression(
          "#1777A7",
          this.layerPaintColorExpression || "#11b4da",
          selectedFrameIds
        )
      );
    }
  }

  public addUnclusteredPointLayerUS(map: mapboxgl.Map) {
    map.addLayer({
      id: this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
      type: "symbol",
      source: FRAME_ONLY_SOURCE_ID,
      layout: {
        "icon-image": ["concat", ["get", "prohibitionStatus"], "-symbol"],
        "icon-allow-overlap": true,
        "icon-size": 0.24,
      },
      paint: {
        "icon-color": "#11b4da",
        "icon-halo-blur": 0,
        "icon-halo-width": 1,
      },
      minzoom: this.FRAME_UNCLUSTERED_LAYER_MIN_ZOOM,
      maxzoom: this.FRAME_UNCLUSTERED_LAYER_MAX_ZOOM,
    });
  }

  public addUnclusteredPointLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
      type: "circle",
      source: FRAME_ONLY_SOURCE_ID,
      paint: {
        "circle-color": "#11b4da",
        "circle-radius": 6,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
      minzoom: this.FRAME_UNCLUSTERED_LAYER_MIN_ZOOM,
      maxzoom: this.FRAME_UNCLUSTERED_LAYER_MAX_ZOOM,
    });
  }

  public addFramesOuterLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.FRAME_ICON_OUTER_LAYER_ID,
      type: "symbol",
      source: FRAME_SOURCE_ID,
      layout: {
        "icon-image": [
          "concat",
          ["get", "prohibitionStatus"],
          ["case", ["has", "orientationInDegrees"], "-directional", "-non-directional"],
          "-marker",
        ],
        "icon-allow-overlap": true,
        "icon-size": 0.7,
        "icon-rotate": ["coalesce", ["get", "orientationInDegrees"], this.DEFAULT_ORIENTATION_DEGREE],
        "icon-rotation-alignment": "map",
      },
      paint: {
        "icon-color": "#11b4da",
        "icon-halo-blur": 0,
        "icon-halo-width": 1,
      },
      minzoom: this.FRAME_ICON_LAYER_MIN_ZOOM,
    });
  }
  public addFramesLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.FRAME_ICON_LAYER_ID,
      type: "symbol",
      source: FRAME_SOURCE_ID,
      layout: {
        "icon-image": [
          "concat",
          ["get", "prohibitionStatus"],
          "-",
          ["get", "faceType"],
          ["case", ["has", "orientationInDegrees"], "-directional", "-non-directional"],
          "-marker",
        ],
        "icon-allow-overlap": true,
        "icon-size": 0.33,
        "icon-rotate": ["coalesce", ["get", "orientationInDegrees"], this.DEFAULT_ORIENTATION_DEGREE],
        "icon-rotation-alignment": "map",
      },
      paint: {
        "icon-color": "#11b4da",
      },
      minzoom: this.FRAME_ICON_LAYER_MIN_ZOOM,
    });
  }

  public addFramesLabelLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.FRAME_LABEL_LAYER_ID,
      type: "symbol",
      source: FRAME_SOURCE_ID,
      layout: {
        "icon-image": "Text_holder",
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        "text-field": this.getTextField("mediaOwnerReferenceIdForFrame"),
        "icon-offset": [0, -2],
        "icon-text-fit": "both",
        "icon-text-fit-padding": [8, 8, 18, 8],
        "text-size": 12,
        "text-offset": [0, -2],
        "text-justify": "center",
        visibility: "none",
      },
      paint: {
        "text-color": "#1777A7",
      },
    });
  }

  private getTextField(field: string) {
    const rawValue = ["get", field];
    const lengthMoreThan10 = [">", ["length", rawValue], 10];
    const truncateTo10Chars = ["concat", ["slice", rawValue, 0, 10], "..."];
    return ["case", lengthMoreThan10, truncateTo10Chars, rawValue];
  }

  public applySelectedFramesFilter(selectedFrames: Frame[]) {
    this.applyFilters(this.getFilterExpressionForSelectedFrames(selectedFrames));
  }

  public applyFilterOnAllFrames(allFilters) {
    const mapBoxExpressionsForSelectedFilters = this.getFilterExpressionForSelectedFilters(allFilters?.filters);
    const mapBoxExpressionsForSelectedAudienceRange = this.getFilterExpressionForSelectedAudienceRange(
      allFilters?.audience
    );
    this.applyFilters([...mapBoxExpressionsForSelectedFilters, ...mapBoxExpressionsForSelectedAudienceRange]);
  }

  public applyFilterOnSelectedFrames(selectedFrames: Frame[], allFilters) {
    const mapBoxExpressionsForSelectedFrameIdFilter = this.getFilterExpressionForSelectedFrames(selectedFrames);
    const mapBoxExpressionsForSelectedFilters = this.getFilterExpressionForSelectedFilters(allFilters?.filters);
    const mapBoxExpressionsForSelectedAudienceRange = this.getFilterExpressionForSelectedAudienceRange(
      allFilters?.audience
    );
    this.applyFilters([
      ...mapBoxExpressionsForSelectedFrameIdFilter,
      ...mapBoxExpressionsForSelectedFilters,
      ...mapBoxExpressionsForSelectedAudienceRange,
    ]);
  }

  private applyFilters(mapBoxExpressionsForSelectedFilters: any[]) {
    this.popUp.remove();
    this.frameLayers.forEach((layer) => {
      this.map.setFilter(layer, ["all", ...mapBoxExpressionsForSelectedFilters]);
    });
    this.audienceIndexLayer.filterNullAudienceIndex(this.map, mapBoxExpressionsForSelectedFilters);
    this.audienceIndexHeatmapLayer.filterNullAudienceIndex(this.map, mapBoxExpressionsForSelectedFilters);
    this.mapEventObserver.setFetchAssociatedPOIData(MapEventTypes.FILTER, true);
  }

  getFilterExpressionForSelectedFrames(selectedFrames: Frame[]): any[] {
    const filterFrameIds = selectedFrames.map((frame) => frame.frameId);
    return [["in", ["get", "frameId"], ["literal", filterFrameIds]]];
  }

  getFilterExpressionForSelectedFilters(selectedFilters: object): any[] {
    return Object.keys(selectedFilters).map((filterType) => [
      "in",
      ["get", filterType],
      ["literal", selectedFilters[filterType]],
    ]);
  }

  getFilterExpressionForSelectedAudienceRange(audience): any[] {
    const minAudienceIndex = audience?.audienceIndexRange?.min;
    const maxAudienceIndex = audience?.audienceIndexRange?.max;
    if (_.isUndefined(minAudienceIndex) || _.isUndefined(maxAudienceIndex)) return [];
    return [
      [">=", ["number", ["get", "audienceIndex"], 0], minAudienceIndex],
      ["<=", ["number", ["get", "audienceIndex"], 0], maxAudienceIndex],
    ];
  }

  public resetSelectedFrameFilters() {
    applyFilter(this.map, [], this.frameLayers);
    this.audienceIndexLayer.filterNullAudienceIndex(this.map);
    this.audienceIndexHeatmapLayer.filterNullAudienceIndex(this.map);
  }

  public applyHighlightedBorderToFrameLayer(selectedFrameIds: string[]) {
    if (this.regionBasedFrameColorCoding) {
      this.map.setPaintProperty(
        this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
        "icon-halo-width",
        this.frameLayerHighlightPaintColorExpression(3, 1, selectedFrameIds)
      );
      this.map.setPaintProperty(
        this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
        "icon-color",
        this.frameLayerHighlightPaintColorExpression(
          "#000",
          this.layerPaintColorExpression ? this.layerPaintColorExpression : "#11b4da",
          selectedFrameIds
        )
      );

      this.map.setPaintProperty(
        this.FRAME_ICON_LAYER_ID,
        "icon-color",
        this.frameLayerHighlightPaintColorExpression(
          "#000",
          this.layerPaintColorExpression ? this.layerPaintColorExpression : "#11b4da",
          selectedFrameIds
        )
      );
    } else {
      this.map.setPaintProperty(
        this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
        "circle-stroke-width",
        this.frameLayerHighlightPaintColorExpression(3, 1, selectedFrameIds)
      );
      this.map.setPaintProperty(
        this.FRAME_UNCLUSTERED_POINT_LAYER_ID,
        "circle-stroke-color",
        this.frameLayerHighlightPaintColorExpression("#1777A7", "#fff", selectedFrameIds)
      );
      this.map.setPaintProperty(
        this.FRAME_ICON_LAYER_ID,
        "icon-color",
        this.frameLayerHighlightPaintColorExpression(
          "#1777A7",
          this.layerPaintColorExpression ? this.layerPaintColorExpression : "#11b4da",
          selectedFrameIds
        )
      );
    }
    this.map.setPaintProperty(
      this.FRAME_ICON_OUTER_LAYER_ID,
      "icon-color",
      this.frameLayerHighlightPaintColorExpression(
        "#000",
        this.layerPaintColorExpression ? this.layerPaintColorExpression : "#11b4da",
        selectedFrameIds
      )
    );
  }

  public resetFrameSource() {
    this.popUp?.remove();
    this.resetMapSource(FRAME_SOURCE_ID);
    this.resetMapSource(FRAME_ONLY_SOURCE_ID);
  }

  private setupFrameSource() {
    this.map.addSource(FRAME_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    this.map.addSource(FRAME_ONLY_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  }

  private updateFrameSource(featureCollection: any[]) {
    this.map.getSource(FRAME_SOURCE_ID)?.setData({
      type: "FeatureCollection",
      features: featureCollection,
    });
    this.map.getSource(FRAME_ONLY_SOURCE_ID)?.setData({
      type: "FeatureCollection",
      features: this.showLocationLayer
        ? featureCollection.filter((feature) => !feature.properties.lineDetails?.containerName)
        : featureCollection,
    });
    this.audienceIndexLayer.filterNullAudienceIndex(this.map);
    this.audienceIndexHeatmapLayer.filterNullAudienceIndex(this.map);
  }

  private loadFrameImagesforMap() {
    //non-prohobitions
    this.map.loadImage("../../../../assets/images/classic_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("-classic-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/classic_non_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("-classic-non-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/digital_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("-digital-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/digital_non_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("-digital-non-directional-marker", image, { sdf: true });
    });

    //level 1 symbols
    this.map.loadImage("../../../../assets/images/prohibition_accepted_level_1_big.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("ACCEPTED-symbol", image, { sdf: true });
      this.map.addImage("ACCEPTED-classic-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/prohibition_potentially_rejected_level_1_big.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("POTENTIALLY_REJECTED-symbol", image, { sdf: true });
      this.map.addImage("POTENTIALLY_REJECTED-classic-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/prohibition_rejected_level_1_big.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("REJECTED-symbol", image, { sdf: true });
      this.map.addImage("REJECTED-classic-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/non_prohibition.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("-symbol", image, { sdf: true });
    });

    //non-directional
    this.map.loadImage("../../../../assets/images/prohibition_rejected_classic_non_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("REJECTED-classic-non-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/prohibition_accepted_classic_non_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("ACCEPTED-classic-non-directional-marker", image, { sdf: true });
    });
    this.map.loadImage(
      "../../../../assets/images/prohibition_potentially_rejected_classic_non_directional.png",
      (error, image) => {
        if (error) {
          throw error;
        }
        this.map.addImage("POTENTIALLY_REJECTED-classic-non-directional-marker", image, { sdf: true });
      }
    );
    this.map.loadImage("../../../../assets/images/prohibition_rejected_digital_non_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("REJECTED-digital-non-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/prohibition_accepted_digital_non_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("ACCEPTED-digital-non-directional-marker", image, { sdf: true });
    });

    this.map.loadImage(
      "../../../../assets/images/prohibition_potentially_rejected_digital_non_directional.png",
      (error, image) => {
        if (error) {
          throw error;
        }
        this.map.addImage("POTENTIALLY_REJECTED-digital-non-directional-marker", image, { sdf: true });
      }
    );

    //directional status symbol
    this.map.loadImage("../../../../assets/images/prohibition_accepted_digital.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("ACCEPTED-digital-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/prohibition_rejected_digital.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("REJECTED-digital-directional-marker", image, { sdf: true });
    });
    this.map.loadImage("../../../../assets/images/prohibition_potentially_rejected_digital.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("POTENTIALLY_REJECTED-digital-directional-marker", image, { sdf: true });
    });

    //directional arc
    this.map.loadImage("../../../../assets/images/directional_semicircle.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("ACCEPTED-directional-marker", image, { sdf: true });
      this.map.addImage("REJECTED-directional-marker", image, { sdf: true });
      this.map.addImage("POTENTIALLY_REJECTED-directional-marker", image, { sdf: true });
    });
  }

  private displayFrameDetailsForLayers(layerIds: string[]) {
    layerIds.map((layerId) => {
      this.map.on("mouseenter", layerId, () => this.changeCursorToPointer());
      this.map.on("mouseleave", layerId, () => this.resetCursor());
      this.map.on("click", layerId, (e) => this.displayPopUp(e.lngLat, e.features[0]));
    });
  }

  private setPopupHTMLAndWireEvents() {
    this.popUp.setHTML(document.getElementById("frameDetailsRef").innerHTML);
    this.functionToUpdateFrameDetailsPopUp();
  }

  private displayPopUp(clickedLngLat, frameDetails) {
    const coordinates = frameDetails.geometry.coordinates.slice();
    while (Math.abs(clickedLngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += clickedLngLat.lng > coordinates[0] ? 360 : -360;
    }

    this.functionToUpdateFrameDetailsPopUp(frameDetails).subscribe(() => {
      this.setPopUpCoordinates(coordinates);
    });
  }

  private setPopUpCoordinates(coordinates: number[]) {
    setTimeout(() => {
      this.popUp.setLngLat(coordinates).addTo(this.map);
      this.setPopupHTMLAndWireEvents();
    });
  }

  private changeCursorToPointer() {
    this.map.getCanvas().style.cursor = "pointer";
  }

  private resetCursor() {
    this.map.getCanvas().style.cursor = "";
  }

  private convertToGeoJson(frame: Frame) {
    const frameSpecifications = frame.specifications;
    const feature = {
      type: "Feature",
      properties: {
        formatName: frame.format?.formatName,
        faceType: frame.format?.faceType?.toLocaleLowerCase(),
        frameId: frame.frameId,
        mediaOwnerReferenceIdForFrame: frame.mediaOwnerReferenceIdForFrame,
        mediaOwnerName: frame.mediaOwner?.name,
        address: frame.geography?.address,
        impacts: frame.formattedImpacts || frame.frameStats?.averageImpacts,
        panelName: frame.panelName == null ? frame.geography?.address : frame.panelName,
        postcode: frame.geography?.postcode,
        environment: frame.environment.name,
        direction: frame.geography?.frameDirection?.code || "",
        height: frame.format?.height,
        heightUnit: frame.format?.heightUnits,
        width: frame.format?.width,
        widthUnit: frame.format?.widthUnits,
        frameSize: frame.format?.size,
        sourceSystem: frame.sourceSystem,
        noNetworkAssociatedFrames: frame.network?.associatedFrameIds?.length,
        defaultImages: frameSpecifications?.imageUrls?.length ? JSON.stringify(frameSpecifications.imageUrls) : "[]",
        uploadedImages: JSON.stringify(frame.photos) ? frame.photos : {},
        latitude: `${frame.geography?.location.coordinates[1]}`,
        longitude: `${frame.geography?.location.coordinates[0]}`,
        blacklisted: frame.isBlacklistedFrame,
        unitRationale: frame.unitRationale,
        audienceIndex: frame.audienceIndex,
        audienceOnTargetPercentage: frame.audienceOnTargetPercentage,
        illumination: frame.format?.illuminated ? "Yes" : "No",
        lineDetails: frame.lineDetails,
        read: frame.format.primaryRead,
      },
      geometry: {
        type: "Point",
        coordinates: frame.geography.location.coordinates,
      },
    };
    if (frame.prohibition?.status) {
      feature.properties["prohibitionStatus"] = frame.prohibition.status;
      feature.properties["associatedPOIDetails"] = frame.prohibition.associatedPOIDetails;
    } else {
      feature.properties["prohibitionStatus"] = "";
    }

    if (frame.geography?.frameDirection) {
      feature.properties["orientationInDegrees"] = frame.geography.frameDirection?.orientationInDegrees;
    }
    return feature;
  }

  private resetMapSource(sourceId: string) {
    this.map?.getSource(sourceId).setData({
      type: "FeatureCollection",
      features: [],
    });
  }

  public rerenderPopup() {
    setTimeout(() => this.setPopupHTMLAndWireEvents());
  }

  toggleFrameLabels() {
    toggleLayer(this.FRAME_LABEL_LAYER_ID, this.map);
  }
}
